import {useState, useEffect} from 'react';
import {
  Container,
  Grid,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  Typography,
  Button
} from '@mui/material';
import axios from 'axios';
import {update, ref} from 'firebase/database';
import {useNavigate} from 'react-router-dom';
import {useAuth} from 'context/AuthContext';
import {db} from 'firebaseConfig';

export default function TermsAndConditions() {
  const {currentUser} = useAuth();

  const [acceptUseOfDataForResearch, setAcceptUseOfDataForResearch] = useState(false);

  const handleAcceptUseOfDataForResearch = (event) => {
    setAcceptUseOfDataForResearch(event.target.value);
  };

  const [submissionIp, setSubmissionIp] = useState('');

  const navigate = useNavigate();

  async function getIpData(event) {
    const res = await axios.get('https://geolocation-db.com/json/');
    setSubmissionIp(res.data.IPv4);
  }

  useEffect(() => {
    getIpData();
  }, []);

  const writeToDatabase = () => {
    update(ref(db, `/${currentUser.uid}`), {
      acceptUseOfDataForResearch,
      acceptUseOfDataForResearchAtTimestamp: Date.now(),
      acceptUseOfDataForResearchAtIp: submissionIp
    });
    navigate('/main', {replace: true});
  };

  return (
    <Container maxWidth="md">
      <Grid
        container
        m="auto"
        sx={{minHeight: '100vh'}}
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          justifyContent="center"
          alignItems="center"
          item
          container
          rowSpacing={4}
          columnSpacing={4}
          spacing={4}
          p={4}
        >
          <Grid item xs={10}>
            <Typography align="center" paragraph component="h1" variant="h3">
              Research purposes
            </Typography>
            <Typography align="center" paragraph>
              The University of the Basque Country is doing research about gaming
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="acceptUseOfDataForResearch">
                How do you feel about using your data for research purposes?
              </FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={acceptUseOfDataForResearch}
                onChange={handleAcceptUseOfDataForResearch}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Yeah, I'm cool with contributing to science"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Nah, I think I'll pass."
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* {apiError && (
            <Grid xs={12}>
              <Typography>{apiError}</Typography>
            </Grid>
          )} */}
          <Grid item xs={12}>
            <Button
              fullWidth
              disableElevation
              size="large"
              variant="contained"
              onClick={writeToDatabase}
            >
              Cool, let's start
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
