import {createTheme} from '@mui/material/styles';

const blue = '#6074cb';
export const blueGradient = 'linear-gradient(45deg, #3f51b5, #6074cb, #3f51b5)'; //
const yellow = '#f5c664';

const options = {
  palette: {
    mode: 'dark',
    primary: {
      main: blue
    },
    secondary: {
      main: yellow,
      contrastText: '#000'
    }
  },
  typography: {
    fontFamily: ['Poppins'].join(','),
    body1: {
      fontSize: '20px'
    },
    body2: {
      fontSize: '16px'
    },
    h1: {
      fontWeight: 'bold',
      lineHeight: 1
    },
    h2: {
      fontWeight: 'bold',
      lineHeight: 0.95
    },
    h3: {
      fontWeight: 'bold',
      lineHeight: 0.95
    },
    h4: {
      fontWeight: 'bold'
    },
    button: {
      textTransform: 'none'
    },
    a: {
      textDecoration: 'none'
    }
  },
  components: {
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: '1.8rem',
          fontWeight: 'bold',
          lineHeight: 1,
          '&.Mui-active': {
            color: blue,
            fontWeight: 'bold'
          }
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '32px',
          height: '32px',
          marginRight: 5
        },
        text: {
          fontSize: 16,
          fontWeight: 'bold'
        }
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '&:hover': {
            border: '2px solid',
            borderColor: blue
          }
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          '&::before, &::after': {
            borderColor: '#cacaca'
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          fontSize: '1.4rem',
          fontWeight: 'bold'
        },
        containedPrimary: {
          background: blueGradient
        }
      }
    }
  }
};

export default createTheme(options);
