import {useState, useEffect} from 'react';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {onAuthStateChanged} from 'firebase/auth';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import EmailVerified from 'anonymous/components/EmailVerified';
import Login from 'anonymous/components/Login';
import Register from 'anonymous/components/Register';
import VerifyEmail from 'anonymous/components/VerifyEmail';
import {
  REPORT,
  WRONG_USER,
  HEXACO,
  LOL,
  VERIFY_EMAIL,
  LOGIN,
  REGISTER,
  CONSENT,
  EMAIL_VERIFIED,
  MAIN,
  HOME,
  LOGGED
} from 'config/router/paths';
import {auth} from 'firebaseConfig';
import HexacoQuestionnaire from 'logged/components/HexacoQuestionnaire';
import Home from 'logged/components/Home';
import LolAccount from 'logged/components/LolAccount';
import ThatIsNotYou from 'logged/components/LolAccount/components/ThatIsNotYou';
import MainSteps from 'logged/components/MainSteps';
import Report from 'logged/components/Report';
import TermsAndConditions from 'logged/components/TermsAndConditions';
import PrivateRoute from 'router/PrivateRoute';
import PublicRoute from 'router/PublicRoute';
import theme from 'theme/theme';
import {AuthProvider} from './context/AuthContext';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [timeActive, setTimeActive] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider value={{currentUser, timeActive, setTimeActive}}>
        <BrowserRouter>
          <Routes>
            <Route path={LOGGED} element={<PrivateRoute />}>
              <Route path={EMAIL_VERIFIED} element={<EmailVerified />} />
              <Route path={MAIN} element={<MainSteps />} />
              <Route path={HOME} element={<Home />} />
              <Route path={CONSENT} element={<TermsAndConditions />} />
              <Route path={HEXACO} element={<HexacoQuestionnaire />} />
              <Route path={LOL} element={<LolAccount />} />
              <Route path={WRONG_USER} element={<ThatIsNotYou />} />
              <Route path={REPORT} element={<Report />} />
            </Route>
            <Route path="/" element={<PublicRoute />}>
              <Route path={LOGIN} element={<Login />} />
              <Route path={REGISTER} element={<Register />} />
              <Route path={VERIFY_EMAIL} element={<VerifyEmail />} />
            </Route>
            {/* <Route
              path="*"
              element={
                !currentUser ? <Navigate replace to={LOGIN} /> : <Navigate replace to={MAIN} />
              }
            /> */}
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
