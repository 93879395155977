import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Grid, Typography, Button} from '@mui/material';
import {Link} from 'react-router-dom';
import {LOL} from 'config/router/paths';
import BlitzcrankEmote from '../images/blitzcrankEmote.webp';

export default function ThatIsNotYou() {
  return (
    <Grid
      container
      m="auto"
      sx={{minHeight: '100vh'}}
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <img src={BlitzcrankEmote} alt="wrong account" />
      </Grid>
      <Grid item>
        <Typography paragraph variant="h4" align="center">
          That does not compute
        </Typography>
      </Grid>
      <Grid item>
        <Button
          startIcon={<ArrowBackIcon />}
          size="large"
          variant="contained"
          disableElevation
          fullWidth
          component={Link}
          to={LOL}
        >
          Try again
        </Button>
      </Grid>
    </Grid>
  );
}
