import {useState, useRef} from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Container,
  Grid,
  Checkbox,
  FormControlLabel,
  Box,
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  IconButton,
  Collapse,
  Alert,
  Divider
} from '@mui/material';
import {useNavigate, Link} from 'react-router-dom';
import {LOGIN, MAIN, VERIFY_EMAIL, LEGAL} from 'config/router/paths';
import {useAuth} from 'context/AuthContext';

export default function Register() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmPasswordRef = useRef();

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {registerWithEmail} = useAuth();
  async function handleSubmit(e) {
    e.preventDefault();
    if (passwordRef.current.value !== confirmPasswordRef) {
      return setError('Passwords do not match');
    }
    try {
      setError('');
      setLoading(true);
      await registerWithEmail(emailRef.current.value, passwordRef.current.value);
      navigate(VERIFY_EMAIL);
    } catch {
      setError('Failed to register');
    }
    setLoading(false);
  }

  const {loginWithGoogle} = useAuth();
  async function handleLoginWithGoogle(e) {
    try {
      setError('');
      setLoading(true);
      await loginWithGoogle();
      navigate(MAIN);
    } catch {
      setError('Failed to register');
    }
    setLoading(false);
  }

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword((v) => !v);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [openAlert, setOpenAlert] = useState(true);

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={4}
        justifyContent="center"
        alignItems="center"
        sx={{minHeight: '100vh'}}
        p={2}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h2" component="h1" paragraph align="center">
            Create an account
          </Typography>
          <Box mt={3}>
            {error && (
              <Collapse in={openAlert}>
                <Alert
                  variant="filled"
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setOpenAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                  sx={{mb: 2}}
                >
                  {error}
                </Alert>
              </Collapse>
            )}
            <form onSubmit={handleSubmit} name="registration_form">
              <Box my={2}>
                <TextField
                  type="email"
                  label="Email"
                  placeholder="Enter your email"
                  required
                  ref={emailRef}
                  variant="standard"
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="username"
                  aria-describedby="email"
                />
              </Box>
              <Box my={2}>
                <FormControl variant="standard" fullWidth required>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Enter your password"
                    ref={passwordRef}
                    id="password"
                    name="password"
                    aria-describedby="password"
                    autoComplete="on"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>

              <Box my={2}>
                <FormControl variant="standard" fullWidth required>
                  <InputLabel htmlFor="password">Confirm password</InputLabel>
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Confirm password"
                    ref={confirmPasswordRef}
                    autoComplete="on"
                    id="password"
                    name="password"
                    aria-describedby="password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>

              <Box my={2}>
                <FormControlLabel
                  required
                  control={<Checkbox />}
                  label={
                    <Typography>
                      I have read and I'm cool with the{' '}
                      <Link style={{color: 'white'}} to={LEGAL} taget="_blank">
                        Privacy policy
                      </Link>
                    </Typography>
                  }
                />
              </Box>

              <Box my={2}>
                <Button
                  disabled={loading}
                  type="submit"
                  size="large"
                  variant="contained"
                  disableElevation
                  fullWidth
                >
                  Sign up with email
                </Button>
              </Box>
            </form>

            <Divider> OR </Divider>

            <Box mt={2}>
              <Button
                endIcon={<GoogleIcon />}
                size="large"
                variant="outlined"
                sx={{borderRadius: 10, color: '#DB4437', borderColor: '#DB4437'}}
                disableElevation
                fullWidth
                onClick={handleLoginWithGoogle}
              >
                Sign up with
              </Button>
            </Box>
            <Box mt={2}>
              <Button
                endIcon={<FacebookIcon />}
                sx={{borderRadius: 10, color: '#4267B2', borderColor: '#4267B2'}}
                size="large"
                variant="outlined"
                disableElevation
                fullWidth
                disabled
              >
                Sign up with
              </Button>
            </Box>
            <Box mt={2}>
              <Button
                disableElevation
                component={Link}
                fullWidth
                to={LOGIN}
                size="large"
                variant="text"
                color="inherit"
                sx={{fontWeight: '400'}}
                startIcon={<ArrowBackIcon />}
              >
                Back to login
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}
