import {useState, useEffect} from 'react';
import SignLanguageIcon from '@mui/icons-material/SignLanguage';
import {Container, Grid, Button, Typography} from '@mui/material';
// import {sendEmailVerification} from 'firebase/auth';
import {useNavigate, Link} from 'react-router-dom';
import {useAuth} from '../../../context/AuthContext';
// import {auth} from '../../../firebase';
import catInSpace from './images/catInSpace.svg';

function VerifyEmail() {
  const {currentUser} = useAuth();
  const [time, setTime] = useState(10);
  const {timeActive, setTimeActive} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      currentUser
        ?.reload()
        .then(() => {
          if (currentUser?.emailVerified) {
            clearInterval(interval);
            navigate('/');
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }, 1000);
  }, [navigate, currentUser]);

  useEffect(() => {
    let interval = null;
    if (timeActive && time !== 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    } else if (time === 0) {
      setTimeActive(false);
      setTime(60);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timeActive, time, setTimeActive]);

  return (
    <Container maxWidth="sm">
      <Grid
        container
        spacing={4}
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{minHeight: '100vh'}}
        p={2}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h3" component="h1" align="center" mb={-5}>
            Well done!
          </Typography>
          <img
            src={catInSpace}
            width="100%"
            height="100%"
            object-fit="cover"
            overflow="hidden"
            alt="got email"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" fontWeight="bold" paragraph align="center">
            Didn't get an email?
          </Typography>
          <Button
            size="large"
            variant="contained"
            fullWidth
            component={Link}
            to="/"
            disabled={timeActive}
            endIcon={<SignLanguageIcon />}
          >
            Get started
          </Button>
          {timeActive && (
            <Typography variant="h5" fontWeight="bold" paragraph align="center">
              You will be redirected in {time} seconds
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default VerifyEmail;
