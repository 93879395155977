import {useState, useEffect} from 'react';
import axios from 'axios';
import {update, ref} from 'firebase/database';
import {useNavigate} from 'react-router-dom';
import {MAIN} from 'config/router/paths';
import {useAuth} from 'context/AuthContext';
import {db} from 'firebaseConfig';
import LolAccountView from './LolAccountView';
import Champions from './champions.json';

const API_KEY = 'RGAPI-8ba75f99-eec0-4070-86ca-e8cbf5820329';

const fakeUser1 = {
  summonerLevel: 101,
  profileIconId: 4804,
  topChampionName: [
    {},
    {
      id: 266,
      name: 'Aatrox'
    }
  ]
};
const fakeUser2 = {
  summonerLevel: 23,
  profileIconId: 4400,
  topChampionName: [
    {},
    {
      id: 7,
      name: 'Leblanc'
    }
  ]
};

const fakeUser3 = {
  summonerLevel: 432,
  profileIconId: 5000,
  topChampionName: [
    {},
    {
      id: 80,
      name: 'Master Yi'
    }
  ]
};

export default function LolAccount() {
  const {currentUser} = useAuth();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    summonerName: '',
    region: ''
  });
  const [playerData, setPlayerData] = useState({});
  const [submissionIp, setSubmissionIp] = useState('');
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [topChampionId, setTopChampionId] = useState({});

  function handleInputChanged(event) {
    setForm((currentForm) => ({
      ...currentForm,
      [event.target.name]: event.target.value
    }));
  }

  const searchForTopChampionId = async (summonerId) => {
    const id = await summonerId;
    const getTopChampionBySummonerIdUrl = `https://${form.region}.api.riotgames.com/lol/champion-mastery/v4/champion-masteries/by-summoner/${id}?api_key=${API_KEY}`;
    try {
      setApiError('');
      setLoading(true);
      await axios.get(getTopChampionBySummonerIdUrl).then(function (response) {
        setTopChampionId(response.data[0].championId);
      });
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        setApiError(`Could not find champion data`);
      } else if (error.request) {
        // The request was made but no response was received
        setApiError(`Could not find champion data`);
      } else {
        // Something happened in setting up the request that triggered an Error
        setApiError(`Could not find champion data`);
      }
    }
  };

  const searchForPlayer = async (e) => {
    const getSummonerByNameUrl = `https://${form.region}.api.riotgames.com/lol/summoner/v4/summoners/by-name/${form.summonerName}?api_key=${API_KEY}`;
    try {
      setApiError('');
      setLoading(true);
      await axios.get(getSummonerByNameUrl).then(function (response) {
        setPlayerData(response.data);
        searchForTopChampionId(response.data.id);
      });
    } catch (error) {
      if (error.response) {
        // Request made and server responded
        setApiError(`That account doesn't seem to exist`);
      } else if (error.request) {
        // The request was made but no response was received
        setApiError(`That account doesn't seem to exist`);
      } else {
        // Something happened in setting up the request that triggered an Error
        setApiError(`That account doesn't seem to exist`);
      }
    }
    setLoading(false);
  };

  const champions = Champions;
  let findByKey = (matchKey) =>
    Object.entries(champions.data).find(([key, value]) => value.key === matchKey);
  const topChampionName = findByKey(JSON.stringify(topChampionId));

  async function getIpData(event) {
    const res = await axios.get('https://geolocation-db.com/json/');
    setSubmissionIp(res.data.IPv4);
  }

  useEffect(() => {
    getIpData();
  }, []);

  const saveData = async (e) => {
    e.preventDefault();
    try {
      setApiError('');
      setLoading(true);
      await update(ref(db, `/${currentUser.uid}`), {
        playerData,
        region: form.region,
        summonerName: form.summonerName,
        topChampionId,
        lolConnectedAtIp: submissionIp,
        lolConnectedAtTimestamp: Date.now()
      });
    } catch (error) {
      setApiError('Failed to save the data');
    }
    setLoading(false);
    navigate(MAIN);
  };

  return (
    <LolAccountView
      fakeUser1={fakeUser1}
      fakeUser2={fakeUser2}
      fakeUser3={fakeUser3}
      topChampionName={topChampionName}
      searchForPlayer={searchForPlayer}
      playerData={playerData}
      apiError={apiError}
      submissionIp={submissionIp}
      onSubmit={saveData}
      loading={loading}
      navigate={navigate}
      onInputChanged={handleInputChanged}
      form={form}
    />
  );
}
