import {Navigate, Outlet} from 'react-router-dom';
import {LOGIN} from 'config/router/paths';
import {useAuth} from 'context/AuthContext';

export default function PrivateRoute() {
  const {currentUser} = useAuth();

  if (!currentUser) {
    return <Navigate to={LOGIN} />;
  }

  return (
    <main>
      <Outlet />
    </main>
  );
}
