import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import PetsIcon from '@mui/icons-material/Pets';
import YoutubeSearchedForIcon from '@mui/icons-material/YoutubeSearchedFor';
import {Grid, Typography, Container, Alert, Button, Box} from '@mui/material';
import MobileStepper from '@mui/material/MobileStepper';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';

ReportView.propTypes = {
  printRef: PropTypes.object.isRequired,
  activeStep: PropTypes.number.isRequired,
  maxSteps: PropTypes.number.isRequired,
  onStepChange: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  logoDisplay: PropTypes.string.isRequired,
  reportData: PropTypes.object.isRequired,
  showAndPrint: PropTypes.func.isRequired,
  demoUserReportData: PropTypes.object.isRequired,
  onClickNext: PropTypes.func,
  onClickBack: PropTypes.func
};

export default function ReportView({
  printRef,
  activeStep,
  maxSteps,
  onStepChange,
  steps,
  logoDisplay,
  reportData,
  showAndPrint,
  demoUserReportData,
  onClickNext,
  onClickBack
}) {
  return (
    <Container maxWidth="md" ref={printRef}>
      <SwipeableViews index={activeStep} onChangeIndex={onStepChange} enableMouseEvents>
        {steps.map((step) => (
          <Grid
            key={step.facet}
            container
            justifyContent="center"
            alignItems="center"
            sx={{minHeight: '90vh', pt: 2, pb: 2}}
          >
            <Grid item container alignItems="center">
              <Grid item xs={12} md={4}>
                <img src={step.emote} alt={step.facet} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Typography variant="overtext">You appear to be quite</Typography>
                <Typography variant="h2" color="primary">
                  {step.score === 'High' ? (
                    <ArrowUpwardIcon sx={{fontSize: '1em', mb: -1, ml: -1}} color="primary" />
                  ) : (
                    <ArrowDownwardIcon sx={{fontSize: '1em', mb: -1, ml: -1}} color="primary" />
                  )}
                  {step.score} in
                </Typography>
                <Typography variant="h2" paragraph color="secondary">
                  {step.facet}
                </Typography>
                <Typography>
                  Your score is <b>{reportData.hexaco.opennessToExperience.inquisitiveness}</b>
                </Typography>
                <Typography paragraph>
                  Most people score about <b>{step.average}</b>
                </Typography>
                <Box mb={3}>
                  <Button
                    data-html2canvas-ignore
                    startIcon={<DownloadIcon />}
                    variant="outlined"
                    onClick={showAndPrint}
                    disableRipple
                  >
                    Download this report
                  </Button>
                </Box>
              </Grid>
              <Grid xs={12}>
                <Typography gutterBottom variant="h5" component="h3">
                  <b>What is {step.facet}?</b>
                </Typography>
                <Typography paragraph variant="body2">
                  {step.whatIs}
                </Typography>
                {step.facet === 'Inquisitiveness' ? (
                  <Alert
                    icon={
                      <YoutubeSearchedForIcon fontSize="large" sx={{color: 'secondary.main'}} />
                    }
                    variant="outlined"
                    severity="success"
                    sx={{color: 'white', borderColor: 'secondary.main'}}
                  >
                    You have tried out{' '}
                    <b>{demoUserReportData.lol.totalChampionsPlayed} champions</b> across{' '}
                    <b>{demoUserReportData.lol.totalGamesPlayed} games</b>. That is a{' '}
                    <b>
                      champion trial ratio of{' '}
                      {(demoUserReportData.lol.totalChampionsPlayed /
                        demoUserReportData.lol.totalGamesPlayed) *
                        100}
                      %
                    </b>
                    , and normally people score around {step.championRotation.averageRatio * 100}%.
                  </Alert>
                ) : step.facet === 'Sentimentality' ? (
                  <Alert
                    icon={
                      <YoutubeSearchedForIcon fontSize="large" sx={{color: 'secondary.main'}} />
                    }
                    variant="outlined"
                    severity="success"
                    sx={{color: 'white', borderColor: 'secondary.main'}}
                  >
                    Your matches have prematurely ended in surrender{' '}
                    <b>{demoUserReportData.lol.totalGameEndedInEarlySurrender} times</b> across{' '}
                    <b>{demoUserReportData.lol.totalGamesPlayed} games</b>. The average ratio is{' '}
                    {step.earlySurrender.averageRatio * 100}%, so... that's that.
                  </Alert>
                ) : (
                  <Alert
                    icon={
                      <YoutubeSearchedForIcon fontSize="large" sx={{color: 'secondary.main'}} />
                    }
                    variant="outlined"
                    severity="success"
                    sx={{color: 'white', borderColor: 'secondary.main'}}
                  >
                    You seem to be more interested in skins, emotes and baubles than the average.
                  </Alert>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sx={{display: logoDisplay}}>
              <Typography variant="h5" align="center" fontWeight="bold" sx={{mt: 2, mb: 2}}>
                <PetsIcon sx={{fontSize: '1em', mb: '-2px'}} /> Saiki.gg
              </Typography>
            </Grid>
          </Grid>
        ))}
      </SwipeableViews>
      <MobileStepper
        sx={{backgroundColor: 'black', minHeight: '10vh'}}
        variant="dots"
        steps={maxSteps}
        activeStep={activeStep}
        nextButton={
          <Button size="large" onClick={onClickNext} disabled={activeStep === maxSteps - 1}>
            Next
            <KeyboardArrowRight />
          </Button>
        }
        backButton={
          <Button size="large" onClick={onClickBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Previous
          </Button>
        }
      />
    </Container>
  );
}
