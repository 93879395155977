export const LOGGED = '/u';

export const LOGIN = '/';
export const REGISTER = '/register';
export const RECOVER_PASSWORD = '/recover-password';
export const VERIFY_EMAIL = '/verify-email';
export const MAIN = `${LOGGED}/main`;
export const EMAIL_VERIFIED = `${LOGGED}/email-verified`;
export const HOME = `${LOGGED}/home`;
export const CONSENT = `${LOGGED}/terms-and-conditions`;
export const HEXACO = `${LOGGED}/personality-test`;
export const LOL = `${LOGGED}/league-of-legends-account`;
export const WRONG_USER = `${LOL}/wrong-user`;
export const REPORT = `${LOGGED}/report`;
export const LEGAL = `${LOL}/p/legal-hub`;
