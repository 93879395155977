import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Grid,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  Checkbox,
  Box,
  Button,
  Typography,
  Alert,
  TextField,
  InputAdornment,
  IconButton,
  Divider,
  Collapse
} from '@mui/material';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {REGISTER, MAIN, RECOVER_PASSWORD} from 'config/router/paths';
import saikiLogoWhite from 'shared/images/saikiLogoWhite.svg';

LoginView.propTypes = {
  openAlert: PropTypes.bool.isRequired,
  setOpenAlert: PropTypes.func.isRequired,
  onLoginWithGoogle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onInputChanged: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  onClickShowPassword: PropTypes.func.isRequired,
  onMouseDownPassword: PropTypes.func.isRequired,
  showPassword: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string
};

export default function LoginView({
  error,
  openAlert,
  onLoginWithGoogle,
  setOpenAlert,
  onSubmit,
  form,
  onInputChanged,
  onClickShowPassword,
  onMouseDownPassword,
  showPassword,
  loading
}) {
  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="center"
      sx={{minHeight: '100vh'}}
      p={4}
    >
      <Grid item xs={12} sm={10} md={8} lg={6}>
        <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
          <img src={saikiLogoWhite} height="66" display="inline" alt="logo" />
          <Typography
            fontSize={{xs: '3rem', sm: '5rem'}}
            variant="h2"
            component="h1"
            align="center"
            lineHeight={1}
            paragraph
          >
            Saiki
          </Typography>
        </Box>
        <Box mt={3}>
          {error && (
            <Collapse in={openAlert}>
              <Alert
                variant="filled"
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpenAlert(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{mb: 2}}
              >
                {error}
              </Alert>
            </Collapse>
          )}
        </Box>
        <Box mt={3}>
          <form onSubmit={onSubmit} name="login_form">
            <Box my={2}>
              <TextField
                label="Email"
                variant="standard"
                autoComplete="email"
                fullWidth
                id="email"
                name="email"
                aria-describedby="email"
                value={form.email}
                onChange={onInputChanged}
              />
            </Box>
            <Box my={1} mb={2}>
              <FormControl variant="standard" fullWidth>
                <InputLabel htmlFor="password">Password</InputLabel>
                <Input
                  id="password"
                  autoComplete="password"
                  name="password"
                  onChange={onInputChanged}
                  value={form.password}
                  type={showPassword ? 'text' : 'password'}
                  aria-describedby="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={onClickShowPassword}
                        onMouseDown={onMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <FormControlLabel
                  control={<Checkbox color="primary" name="remember" />}
                  label={<Typography variant="body2">Remember</Typography>}
                />
              </Grid>
              <Grid item>
                <Button
                  disableFocusRipple
                  component={Link}
                  to={RECOVER_PASSWORD}
                  variant="text"
                  color="primary"
                  sx={{
                    padding: 0
                  }}
                >
                  <Typography variant="body2">Reset password</Typography>
                </Button>
              </Grid>
            </Grid>
            <Box my={2}>
              <Button
                disableElevation
                component={Link}
                fullWidth
                to={MAIN}
                size="large"
                variant="contained"
                disabled={loading}
              >
                Login with email
              </Button>
            </Box>
          </form>
          <Box my={2}>
            <Button
              endIcon={<GoogleIcon />}
              size="large"
              variant="outlined"
              sx={{borderRadius: 10, color: '#DB4437', borderColor: '#DB4437'}}
              disableElevation
              fullWidth
              onClick={onLoginWithGoogle}
            >
              Login with
            </Button>
          </Box>
          <Box my={2}>
            <Button
              endIcon={<FacebookIcon />}
              sx={{borderRadius: 10, color: '#4267B2', borderColor: '#4267B2'}}
              size="large"
              variant="outlined"
              disableElevation
              disabled
              fullWidth
            >
              Login with
            </Button>
          </Box>
          <Divider> OR </Divider>
          <Box my={2} display="flex" justifyContent="center">
            <Button
              component={Link}
              to={REGISTER}
              fullWidth
              variant="contained"
              startIcon={<PersonAddAlt1Icon />}
              color="inherit"
              size="large"
              sx={{color: 'primary.main'}}
            >
              Create new account
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
