import {get, ref} from 'firebase/database';
import {useNavigate} from 'react-router-dom';
import {useAuth} from 'context/AuthContext';
import {db} from 'firebaseConfig';

export default function Home() {
  const {currentUser} = useAuth();
  let navigate = useNavigate();

  const termsAndConditionsRef = ref(db, `/${currentUser.uid}/acceptUseOfDataForResearch`);
  get(termsAndConditionsRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        return navigate('/main');
      }
      return navigate('/terms-and-conditions');
    })
    .catch((error) => {
      alert(error);
    });
}
