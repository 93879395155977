import {useState} from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {IconButton, Collapse, Alert} from '@mui/material';
import PropTypes from 'prop-types';

ErrorAlert.propTypes = {
  error: PropTypes.object.isRequired
};

export default function ErrorAlert({error}) {
  const [openAlert, setOpenAlert] = useState(true);
  return (
    <Collapse in={openAlert}>
      <Alert
        variant="filled"
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpenAlert(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{mb: 2}}
      >
        {error}
      </Alert>
    </Collapse>
  );
}
