import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getDatabase} from 'firebase/database';

const firebaseConfig = {
  apiKey: 'AIzaSyDGlMpJlVNKAPyf384HTntLJiWxZ1x_B2s',
  authDomain: 'saiki-react-app.firebaseapp.com',
  databaseURL: 'https://saiki-react-app-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'saiki-react-app',
  storageBucket: 'saiki-react-app.appspot.com',
  messagingSenderId: '256913181494',
  appId: '1:256913181494:web:5784ba9093e7244144c197',
  measurementId: 'G-FM30JSKQZ2'
};

const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
export {db};

const auth = getAuth(app);
export {auth};
