import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LockIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import {
  Grid,
  Box,
  Typography,
  Container,
  Button,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  AppBar,
  Toolbar
} from '@mui/material';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import saikiLogoWhite from 'shared/images/saikiLogoWhite.svg';
import ufoCow from './images/ufoCow.svg';

const headerHeight = 70;

MainStepsView.propTypes = {
  currentUser: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
  error: PropTypes.string
};

export default function MainStepsView({currentUser, onLogout, error, steps, activeStep}) {
  return (
    <>
      <AppBar
        elevation={0}
        position="static"
        sx={{height: headerHeight, borderBottom: '1px solid #6074cb30'}}
      >
        <Toolbar sx={{pt: 1}}>
          <Box display="flex" flexGrow={1}>
            <Link to="/">
              <img src={saikiLogoWhite} height="35" display="inline" alt="logo" />
            </Link>
            <Typography
              sx={{textDecoration: 'none', color: 'white'}}
              component={Link}
              to="/"
              color="cacaca"
              variant="h4"
              ml={1}
              fontWeight="600"
            >
              Saiki
            </Typography>
          </Box>
          <Typography
            display={{xs: 'none', sm: 'block'}}
            noWrap
            mr={2}
            variant="body2"
            maxWidth="33%"
          >
            {currentUser.email}
          </Typography>
          <Button variant="outlined" endIcon={<LogoutIcon />} color="inherit" onClick={onLogout}>
            Log out
          </Button>
          {error && <p>{error}</p>}
        </Toolbar>
      </AppBar>
      <Container maxWidth="lg">
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="center"
          sx={{minHeight: `calc(100vh - ${headerHeight}px)`}}
          p={4}
        >
          <Grid item xs={12} md={6}>
            <Typography variant="h3">Get started</Typography>
            <Typography paragraph>You are three steps away</Typography>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel>{step.label}</StepLabel>
                  <StepContent>
                    <Typography paragraph>{step.description}</Typography>
                    {step.cta && (
                      <Button
                        variant="contained"
                        size="large"
                        component={Link}
                        to={step.linkTo}
                        color="secondary"
                        sx={{mt: 1, mb: 1}}
                        endIcon={<ChevronRightIcon />}
                      >
                        {step.cta}
                      </Button>
                    )}

                    <Box sx={{mt: 1, mb: 2}}>
                      <Box display="flex" alignItems="center">
                        <Button
                          variant="outlined"
                          endIcon={<LockIcon />}
                          disabled
                          sx={{
                            mr: 1.5,
                            color: '#cacaca',
                            borderColor: '#cacaca',
                            fontSize: 16,
                            paddingX: 3
                          }}
                        >
                          {index === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                        {index !== steps.length - 1 && (
                          <Typography color="#cacaca" variant="body2" lineHeight={1}>
                            Complete this step first
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Grid>
          <Grid item xs={12} md={6} sx={{display: {xs: 'none', md: 'block'}}}>
            <img
              src={ufoCow}
              width="100%"
              height="100%"
              object-fit="cover"
              overflow="hidden"
              alt="login"
            />
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
