import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import Button from '@mui/material/Button';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import HexacoAnswers from 'logged/utils/hexaco/answers.json';
import ErrorAlert from 'shared/components/ErrorAlert';

HexacoQuestionnaireView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  questions: PropTypes.array.isRequired,
  currentQuestion: PropTypes.number.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  onChangeQuestion: PropTypes.func.isRequired,
  onAnswer: PropTypes.func.isRequired,
  answers: PropTypes.array.isRequired,
  error: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired
};

StyledIcon.propTypes = {
  icon: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  checked: PropTypes.bool
};

const green = '#32cd32';
const red = '#ff0000';
const yellow = '#daa520';

function StyledIcon(props) {
  const Icon = props.icon;
  return (
    <Icon
      sx={{
        fontSize: {
          xs: 70,
          lg: 100
        },
        backgroundColor: props.checked ? props.color : 'transparent',
        color: props.checked ? 'white' : props.color,
        borderRadius: '50%',
        padding: 1.5,
        border: 0.5,
        borderColor: props.checked ? props.color : `${props.color}50`,
        boxShadow: props.checked ? `0px 0px 32px 0px ${props.color}50` : 'none'
      }}
    />
  );
}

export default function HexacoQuestionnaireView({
  onSubmit,
  questions,
  currentQuestion,
  totalQuestions,
  onClickNext,
  onClickBack,
  onChangeQuestion,
  onAnswer,
  answers,
  error,
  loading
}) {
  return (
    <Grid container justifyContent="center" alignItems="center" sx={{minHeight: '90vh'}}>
      {error !== '' && (
        <Grid item xs={10}>
          <ErrorAlert error={error} />
        </Grid>
      )}
      <Grid item xs={12}>
        <form onSubmit={onSubmit} name="personality_test">
          <FormControl sx={{maxWidth: '90vw', display: 'block', m: 'auto'}}>
            <FormLabel id="hexaco-label">
              <Typography
                fontSize={{xs: 16, md: 30}}
                variant="overline"
                align="center"
                display="block"
                margin="auto"
                color="white"
                lineHeight={1}
                paragraph
                mb={1}
              >
                Do you agree with this sentence?
              </Typography>
              <Typography
                variant="h3"
                fontSize={{xs: 36, md: 52}}
                align="center"
                lineHeight={1}
                sx={{color: 'white', mb: {xs: 1, md: 3}}}
                paragraph
              >
                {questions[currentQuestion].title.en}
              </Typography>
            </FormLabel>
            <SwipeableViews
              index={currentQuestion}
              onChangeIndex={onChangeQuestion}
              enableMouseEvents
            >
              {questions.map((item) => (
                <div key={item.number}>
                  <RadioGroup
                    aria-labelledby="hexaco-label"
                    name="hexaco-answers"
                    onChange={onAnswer}
                    sx={{
                      justifyContent: 'center',
                      alignItems: 'baseline',
                      flexDirection: {xs: 'column', md: 'row'}
                    }}
                  >
                    {HexacoAnswers.map((answer) => (
                      <FormControlLabel
                        key={answer.label.en}
                        labelPlacement="bottom"
                        onClick={onClickNext}
                        sx={{justifyContent: 'center', flexDirection: {xs: 'row', md: 'column'}}}
                        control={
                          answer === HexacoAnswers[0] ? (
                            <Radio
                              value={1}
                              icon={<StyledIcon icon={ThumbDownOffAltIcon} color={red} />}
                              checkedIcon={
                                <StyledIcon checked icon={ThumbDownAltIcon} color={red} />
                              }
                            />
                          ) : answer === HexacoAnswers[1] ? (
                            <Radio
                              value={2}
                              icon={<StyledIcon icon={ThumbDownOffAltIcon} color={red} />}
                              checkedIcon={
                                <StyledIcon checked icon={ThumbDownAltIcon} color={red} />
                              }
                            />
                          ) : answer === HexacoAnswers[2] ? (
                            <Radio
                              value={3}
                              icon={<StyledIcon icon={ThumbsUpDownIcon} color={yellow} />}
                              checkedIcon={
                                <StyledIcon checked icon={ThumbsUpDownIcon} color={yellow} />
                              }
                            />
                          ) : answer === HexacoAnswers[3] ? (
                            <Radio
                              value={4}
                              icon={<StyledIcon icon={ThumbUpOffAltIcon} color={green} />}
                              checkedIcon={
                                <StyledIcon checked icon={ThumbUpAltIcon} color={green} />
                              }
                            />
                          ) : (
                            <Radio
                              value={5}
                              icon={<StyledIcon icon={ThumbUpOffAltIcon} color={green} />}
                              checkedIcon={
                                <StyledIcon checked icon={ThumbUpAltIcon} color={green} />
                              }
                            />
                          )
                        }
                        label={
                          <Typography
                            display="block"
                            sx={{pointerEvents: 'none'}}
                            color={
                              answer === HexacoAnswers[0]
                                ? 'red'
                                : answer === HexacoAnswers[1]
                                ? 'red'
                                : answer === HexacoAnswers[2]
                                ? 'goldenrod'
                                : answer === HexacoAnswers[3]
                                ? 'limegreen'
                                : 'limegreen'
                            }
                          >
                            {answer.label.en}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                  {questions[currentQuestion] === questions[questions.length - 1] && (
                    <Box pt={4} maxWidth="sm" margin="auto">
                      <Button
                        color="primary"
                        size="large"
                        variant="contained"
                        fullWidth
                        type="submit"
                        disabled={loading}
                        endIcon={<ChevronRightIcon />}
                      >
                        Submit answers
                      </Button>
                    </Box>
                  )}
                </div>
              ))}
            </SwipeableViews>
          </FormControl>
          <MobileStepper
            sx={{backgroundColor: 'black', minHeight: '10vh'}}
            variant="progress"
            steps={totalQuestions}
            activeStep={currentQuestion}
            nextButton={
              <Button
                size="large"
                onClick={onClickNext}
                disabled={answers.length <= currentQuestion}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button size="large" onClick={onClickBack} disabled={currentQuestion === 0}>
                <KeyboardArrowLeft />
                Prev
              </Button>
            }
          />
        </form>
      </Grid>
    </Grid>
  );
}
