import {useRef, useState} from 'react';
import html2canvas from 'html2canvas';
import ReportView from './ReportView';
import AestheticAppreciationHigh from './images/aestheticAppreciation-high.png';
import InquisitivenesHigh from './images/inquisitiveness-high.png';
import SentimentalityHigh from './images/sentimentality-low.png';

const steps = [
  {
    facet: 'Inquisitiveness',
    average: 3.19,
    score: 'High',
    emote: InquisitivenesHigh,
    whatIs:
      'The Inquisitiveness scale assesses a tendency to seek information about, and experience with, the natural and human world. Low scorers have little curiosity about the natural or social sciences, whereas high scorers read widely and are interested in travel.',
    championRotation: {
      averageRatio: 0.1
    }
  },
  {
    facet: 'Sentimentality',
    average: 3.5,
    score: 'Low',
    emote: SentimentalityHigh,
    whatIs:
      'The Sentimentality scale assesses a tendency to feel strong emotional bonds with others. Low scorers feel little emotion when saying good-bye or in reaction to the concerns of others, whereas high scorers feel strong emotional attachments and an empathic sensitivity to the feelings of others.',
    earlySurrender: {
      averageRatio: 0.01
    }
  },
  {
    facet: 'Aesthetic Appreciation',
    average: 3.25,
    score: 'High',
    emote: AestheticAppreciationHigh,
    whatIs:
      "The Aesthetic Appreciation scale assesses one's enjoyment of beauty in art and in nature. Low scorers tend not to become absorbed in works of art or in natural wonders, whereas high scorers have a strong appreciation of various art forms and of natural wonders.",
    championRotation: {
      averageRatio: 0.1
    }
  }
];

const demoUserReportData = {
  hexaco: {
    honestyHumility: {
      sincerity: 2.5,
      fairness: 3.25,
      greedAvoidance: 4.25,
      modesty: 4.5
    },
    emotionality: {
      fearfulness: 2.0,
      anxiety: 1.5,
      dependence: 2.75,
      sentimentality: 1.5
    },
    extraversion: {
      socialSelfEsteem: 3.75,
      socialBoldness: 4.75,
      sociability: 3.75,
      liveliness: 4.0
    },
    agreeableness: {
      forgivingness: 3.0,
      gentleness: 3.5,
      flexibility: 3.75,
      patience: 3.75
    },
    conscientiousness: {
      organization: 3.0,
      diligence: 4.5,
      perfectionism: 4.25,
      prudence: 3.75
    },
    opennessToExperience: {
      aestheticAppreciation: 4.0,
      inquisitiveness: 4.75,
      creativity: 4.75,
      unconventionality: 3.25
    },
    altruism: 2.5
  },
  lol: {
    totalGamesPlayed: 100,
    totalChampionsPlayed: 23,
    totalTeamEarlySurrendered: 5,
    totalGameEndedInEarlySurrender: 3
  }
};

export default function Report() {
  const [activeStep, setActiveStep] = useState(0);
  const [logoDisplay, setLogoDisplay] = useState('none');
  const maxSteps = steps.length;

  const reportData = demoUserReportData;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const printRef = useRef();

  const handleDownloadImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/jpg');
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = data;
      link.download = 'saiki_gg-report.jpg';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  const showAndPrint = () => {
    setLogoDisplay('block');
    handleDownloadImage();
    setLogoDisplay('none');
  };

  return (
    <ReportView
      activeStep={activeStep}
      showAndPrint={showAndPrint}
      reportData={reportData}
      onClickNext={handleNext}
      onClickBack={handleBack}
      maxSteps={maxSteps}
      onStepChange={handleStepChange}
      logoDisplay={logoDisplay}
      printRef={printRef}
      steps={steps}
      demoUserReportData={demoUserReportData}
    />
  );
}
