import {useState, useEffect} from 'react';
import SendIcon from '@mui/icons-material/Send';
import {Container, Grid, Button, Typography} from '@mui/material';
import {sendEmailVerification} from 'firebase/auth';
import {useNavigate} from 'react-router-dom';
import {useAuth} from 'context/AuthContext';
import {auth} from 'firebaseConfig';
import gotEmail from './images/gotEmail.svg';

function VerifyEmail() {
  const {currentUser} = useAuth();
  const [time, setTime] = useState(60);
  const {timeActive, setTimeActive} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      currentUser
        ?.reload()
        .then(() => {
          if (currentUser?.emailVerified) {
            clearInterval(interval);
            navigate('/');
          }
        })
        .catch((err) => {
          alert(err.message);
        });
    }, 1000);
  }, [navigate, currentUser]);

  useEffect(() => {
    let interval = null;
    if (timeActive && time !== 0) {
      interval = setInterval(() => {
        setTime((time) => time - 1);
      }, 1000);
    } else if (time === 0) {
      setTimeActive(false);
      setTime(60);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [timeActive, time, setTimeActive]);

  const resendEmailVerification = () => {
    sendEmailVerification(auth.currentUser)
      .then(() => {
        setTimeActive(true);
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <Container maxWidth="sm">
      <Grid
        container
        spacing={4}
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{minHeight: '100vh'}}
        p={2}
      >
        <Grid item xs={12} md={6}>
          <Typography variant="h3" component="h1" align="center" mb={-5}>
            Check your email
          </Typography>
          <img
            src={gotEmail}
            width="100%"
            height="100%"
            object-fit="cover"
            overflow="hidden"
            alt="got email"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" fontWeight="bold" align="center" mt={-10}>
            We sent a verification to
          </Typography>
          <Typography variant="h5" color="secondary" paragraph fontWeight="bold" align="center">
            {currentUser?.email}
          </Typography>
          <Typography paragraph align="center">
            Follow the instruction in the email to verify your account
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography variant="h5" fontWeight="bold" paragraph align="center">
            Didn't get an email?
          </Typography>
          <Button
            size="large"
            variant="contained"
            fullWidth
            onClick={resendEmailVerification}
            disabled={timeActive}
            endIcon={<SendIcon />}
          >
            Resend Email
          </Button>
          {timeActive && (
            <Typography mt={2} variant="h5" paragraph align="center">
              You must wait {time} seconds
            </Typography>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default VerifyEmail;
