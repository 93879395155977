import {useState} from 'react';
import {get, ref} from 'firebase/database';
import {useNavigate} from 'react-router-dom';
import {REPORT, HEXACO, LOL} from 'config/router/paths';
import {useAuth} from 'context/AuthContext';
import {db} from 'firebaseConfig';
import MainStepsView from './MainStepsView';

function MainSteps() {
  const {currentUser, logout} = useAuth();

  const [lolStepCompleted, setLolStepCompleted] = useState(false);
  const [hexacoStepCompleted, setHexacoStepCompleted] = useState(false);
  const [error, setError] = useState();
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      label: 'Link your LOL account',
      description:
        "Let's link your League of Legends account and see what you can learn about yourself",
      cta: 'Connect',
      linkTo: LOL,
      completed: lolStepCompleted
    },
    {
      label: 'Personality test',
      description: 'You will answer a generous amount of questions about yourself.',
      cta: 'Start test',
      linkTo: HEXACO,
      completed: hexacoStepCompleted
    },
    {
      label: 'See results',
      description:
        "Finally, check out the results and let's see what you can learn about yourself.",
      cta: null,
      linkTo: REPORT,
      completed: false
    }
  ];

  // Check if the user has completed the 1st step, by looking if "summonerName" exists in the database
  const summonerNameRef = ref(db, `/${currentUser.uid}/summonerName`);
  get(summonerNameRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        setLolStepCompleted(true);
        setActiveStep(1);
      }
    })
    .catch((error) => {
      setError('Could not fetch information about LOL account from the database');
    });

  // Check if the user has completed the 2nd step, by looking if "finalScore" exists in the database
  const hexacoFinalScoreRef = ref(db, `/${currentUser.uid}/hexaco/finalScore`);
  get(hexacoFinalScoreRef)
    .then((snapshot) => {
      if (snapshot.exists()) {
        setHexacoStepCompleted(true);
        setActiveStep(2);
      }
    })
    .catch((error) => {
      setError('Could not fetch information about test completion from the database');
    });

  // Logout
  let navigate = useNavigate();
  async function handleLogout() {
    setError('');
    try {
      await logout();
      navigate('/');
    } catch {
      setError('Failed to log out');
    }
  }

  return (
    <MainStepsView
      currentUser={currentUser}
      error={error}
      steps={steps}
      activeStep={activeStep}
      onLogout={handleLogout}
    />
  );
}

export default MainSteps;
