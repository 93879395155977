import {createContext, useContext, useState, useEffect} from 'react';
import {GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import PropTypes from 'prop-types';
import {auth} from 'firebaseConfig';

const AuthContext = createContext();

AuthProvider.propTypes = {
  children: PropTypes.object.isRequired
};

// Tutorial: https://www.youtube.com/watch?v=PKwu15ldZ7k and then https://www.youtube.com/watch?v=6XTRElVAZ9Y

export function AuthProvider({children}) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  function registerWithEmail(auth, email, password) {
    return auth.createUserWithEmailAndPassword(auth, email, password);
  }

  function loginWithEmail(auth, email, password) {
    return auth.signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return auth.signOut();
  }

  function resetPassword(email) {
    return auth.sendPasswordResetEmail(email);
  }

  function loginWithGoogle() {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(currentUser));
  }, [currentUser]);

  const value = {
    currentUser,
    registerWithEmail,
    loginWithGoogle,
    loginWithEmail,
    logout,
    resetPassword
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}

export function useAuth() {
  return useContext(AuthContext);
}
