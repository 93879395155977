import {useState, useEffect} from 'react';
import axios from 'axios';
import {update, ref} from 'firebase/database';
import {useNavigate} from 'react-router-dom';
import {MAIN} from 'config/router/paths';
import {useAuth} from 'context/AuthContext';
import {db} from 'firebaseConfig';
// import HexacoScorer from 'logged/utils/hexaco/scorer';
import HexacoItems from 'logged/utils/hexaco/items.json';
import HexacoQuestionnaireView from './HexacoQuestionnaireView';

const questions = HexacoItems;

function HexacoQuestionnaire() {
  const {currentUser} = useAuth();

  const [submissionIp, setSubmissionIp] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const totalQuestions = questions.length;

  async function getIpData(event) {
    const res = await axios.get('https://geolocation-db.com/json/');
    setSubmissionIp(res.data.IPv4);
  }
  useEffect(() => {
    getIpData();
  }, []);

  const sendAnswerToDatabase = async (e) => {
    e.preventDefault();
    try {
      setError('');
      setLoading(true);
      await update(ref(db, `/${currentUser.uid}`), {
        hexaco: answers
      });
    } catch (error) {
      setError('Failed to save the answer');
    }
    setLoading(false);
  };

  const navigate = useNavigate();

  const saveData = async (e) => {
    e.preventDefault();
    try {
      setError('');
      setLoading(true);
      await update(ref(db, `/${currentUser.uid}`), {
        hexacoSubmittedAtTimestamp: Date.now(),
        hexacoSubmittedAtIp: submissionIp
      });
    } catch (error) {
      setError('Failed to save your answers');
    }
    setLoading(false);
    navigate(MAIN);
  };

  const handleAnswer = (event) => {
    setAnswers((answers) => [...answers, event.target.value]);
    sendAnswerToDatabase();
  };

  const handleNext = () => {
    setCurrentQuestion((previousQuestion) => previousQuestion + 1);
  };
  const handleBack = () => {
    setCurrentQuestion((previousQuestion) => previousQuestion - 1);
  };
  const handleQuestionChange = (question) => {
    setCurrentQuestion(question);
  };

  return (
    <HexacoQuestionnaireView
      onSubmit={saveData}
      questions={questions}
      currentQuestion={currentQuestion}
      totalQuestions={totalQuestions}
      onClickNext={handleNext}
      onClickBack={handleBack}
      onChangeQuestion={handleQuestionChange}
      onAnswer={handleAnswer}
      answers={answers}
      error={error}
      loading={loading}
    />
  );
}

export default HexacoQuestionnaire;
