import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {
  Container,
  Grid,
  TextField,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  LinearProgress,
  CircularProgress
} from '@mui/material';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {WRONG_USER} from 'config/router/paths';
import ErrorAlert from 'shared/components/ErrorAlert';
import REGIONS from './utils/regions';

const FakeUser = (props) => (
  <Card sx={{maxWidth: 345}}>
    <CardActionArea component={Link} to={WRONG_USER}>
      <CardMedia
        component="img"
        height="140"
        image={`http://ddragon.leagueoflegends.com/cdn/12.17.1/img/profileicon/${props.fakeUser.profileIconId}.png`}
        alt="champion"
      />
      <CardContent>
        <Typography sx={{fontWeight: 'bold', mr: 1}}>{props.playerData.name}</Typography>
        <Typography>Level {props.fakeUser.summonerLevel}</Typography>
        <Typography variant="overtext" display="block" sx={{fontWeight: 'bold', mt: 1}}>
          Top champion:
        </Typography>
        <Typography>
          <EmojiEventsIcon fontSize="small" sx={{mb: '-2px'}} />{' '}
          {`${props.fakeUser.topChampionName && props.fakeUser.topChampionName[1].name}`}
        </Typography>
      </CardContent>
    </CardActionArea>
  </Card>
);

FakeUser.propTypes = {
  fakeUser: PropTypes.object.isRequired,
  playerData: PropTypes.object.isRequired
};

LolAccountView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fakeUser1: PropTypes.object.isRequired,
  fakeUser2: PropTypes.object.isRequired,
  fakeUser3: PropTypes.object.isRequired,
  searchForPlayer: PropTypes.func.isRequired,
  topChampionName: PropTypes.array,
  playerData: PropTypes.object.isRequired,
  submissionIp: PropTypes.string.isRequired,
  apiError: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  navigate: PropTypes.func.isRequired,
  onInputChanged: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired
};

export default function LolAccountView({
  onSubmit,
  loading,
  fakeUser1,
  fakeUser2,
  fakeUser3,
  topChampionName,
  searchForPlayer,
  playerData,
  form,
  apiError,
  submissionIp,
  navigate,
  onInputChanged
}) {
  return (
    <Container maxWidth="lg">
      {loading && <LinearProgress />}
      <form onSubmit={onSubmit} name="LOL_FORM">
        <Grid
          container
          m="auto"
          sx={{minHeight: '100vh'}}
          justifyContent="center"
          alignItems="center"
        >
          {JSON.stringify(playerData) === '{}' ? (
            <Grid
              justifyContent="center"
              alignItems="center"
              item
              container
              rowSpacing={4}
              columnSpacing={4}
              spacing={4}
              p={4}
            >
              <Grid item xs={10}>
                <Typography align="center" paragraph component="h1" variant="h3">
                  Link to League of Legends<sup>®</sup>
                </Typography>
                <Typography align="center" paragraph>
                  Provide your summoner name and your region. If you don't know them, you can check
                </Typography>
              </Grid>
              {apiError !== '' && (
                <Grid item xs={10}>
                  <ErrorAlert error={apiError} />
                </Grid>
              )}
              <Grid item xs={12} md={6}>
                <TextField
                  variant="standard"
                  fullWidth
                  label="Summoner name"
                  id="summonerName"
                  name="summonerName"
                  aria-describedby="summonerName"
                  onChange={onInputChanged}
                  value={form.summonerName}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  onChange={onInputChanged}
                  variant="standard"
                  fullWidth
                  label="Region"
                  id="region"
                  select
                  name="region"
                  aria-describedby="region"
                  value={form.region}
                  SelectProps={{
                    native: true
                  }}
                  required
                >
                  {REGIONS.map((region) => (
                    <option key={region} value={region}>
                      {region}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  disableElevation
                  size="large"
                  variant="contained"
                  onClick={(e) => searchForPlayer(e)}
                  disabled={!form.region || !form.summonerName || loading}
                  startIcon={loading && <CircularProgress />}
                >
                  Check in database
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid
              justifyContent="center"
              alignItems="center"
              item
              container
              spacing={{xs: 2, md: 4}}
              p={4}
            >
              <Grid item xs={10}>
                <Typography align="center" paragraph component="h1" variant="h3">
                  Which of these are you?
                </Typography>
                <Typography align="center" paragraph>
                  Let's get this right
                </Typography>
              </Grid>
              <Grid item xs={6} md={3}>
                <FakeUser fakeUser={fakeUser1} playerData={playerData} />
              </Grid>
              <Grid item xs={6} md={3}>
                <Card sx={{maxWidth: 345}}>
                  <CardActionArea type="submit">
                    <CardMedia
                      component="img"
                      height="140"
                      image={`http://ddragon.leagueoflegends.com/cdn/12.17.1/img/profileicon/${playerData.profileIconId}.png`}
                      alt="champion"
                    />
                    <CardContent>
                      <Typography sx={{fontWeight: 'bold', mr: 1}}>{playerData.name}</Typography>
                      <Typography gutterBottom>Level {playerData.summonerLevel}</Typography>
                      <Typography
                        variant="overtext"
                        display="block"
                        sx={{
                          fontWeight: 800,
                          mt: 1,
                          fontSize: 20,
                          lineHeight: '30px',
                          fontFamily: 'Poppins'
                        }}
                      >
                        Top champion:
                      </Typography>
                      <Typography>
                        <EmojiEventsIcon fontSize="small" sx={{mb: '-2px'}} />{' '}
                        {`${topChampionName && topChampionName[1].name}`}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <Grid item xs={6} md={3}>
                <FakeUser fakeUser={fakeUser2} playerData={playerData} />
              </Grid>
              <Grid item xs={6} md={3}>
                <FakeUser fakeUser={fakeUser3} playerData={playerData} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </form>
    </Container>
  );
}
