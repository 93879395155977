import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {MAIN} from 'config/router/paths';
import {useAuth} from 'context/AuthContext';
import LoginView from './LoginView';

export default function Login() {
  const {loginWithEmail, loginWithGoogle} = useAuth();
  const navigate = useNavigate();

  const [form, setForm] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [openAlert, setOpenAlert] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((v) => !v);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError('');
      setLoading(true);
      await loginWithEmail(form.email.current.value, form.password.current.value);
      navigate(MAIN);
    } catch {
      setError('Failed to log in');
    }
    setLoading(false);
  }

  async function handleLoginWithGoogle(e) {
    try {
      setError('');
      setLoading(true);
      await loginWithGoogle();
      navigate(MAIN);
    } catch {
      setError('Failed to register');
    }
    setLoading(false);
  }

  function handleInputChanged(event) {
    setForm((currentForm) => ({
      ...currentForm,
      [event.target.name]: event.target.value
    }));
  }

  return (
    <LoginView
      hasConfirmedAccount={false}
      openAlert={openAlert}
      onLoginWithGoogle={handleLoginWithGoogle}
      setOpenAlert={setOpenAlert}
      onSubmit={handleSubmit}
      form={form}
      onInputChanged={handleInputChanged}
      onClickShowPassword={handleClickShowPassword}
      onMouseDownPassword={handleMouseDownPassword}
      showPassword={showPassword}
      loading={loading}
      error={error}
    />
  );
}
